@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.text-shadow {
    text-shadow: 0 0 4px black;
}

.ql-editor img {
    border-radius: 0.5rem;
}
